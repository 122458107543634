import { useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import { loginRequest } from "../../authConfig";

export const SignInButton = () => {
    const { instance } = useMsal();
    const authRequest = {
        ...loginRequest
    };

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(authRequest);
        } else if (loginType === "redirect") {
            instance.loginRedirect(authRequest);
        }
    }

    return (
        <Button
            onClick={() => handleLogin("redirect")} key="loginRedirect"
            color="inherit"
        >
            Sign in
        </Button>
    )
};