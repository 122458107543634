import { unstable_createMuiStrictModeTheme as createMuiTheme, adaptV4Theme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
export const theme = createMuiTheme(adaptV4Theme({
    palette: {
        primary: {
            main: '#e0d9b8',
            light: '#f2f0e3'
        },
        secondary: {
            main: '#0b2321',
        },
        danger: {
            main: '#e67a84',
        },
        error: {
            main: '#e67a84',
        },
        background: {
            default: '#f2f0e3',
        },
        gray: {
            main: 'rgba(0, 0, 0, 0.13)',
            dark: 'rgba(0, 0, 0, 0.30)',
            light: 'rgba(0, 0, 0, 0.05)',
            ultralight: 'rgba(0, 0, 0, 0.03)'
        }
    },
}));
