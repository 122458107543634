import React, { useContext } from "react";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { DataContext } from '../contexts/DataContext';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


export default function FiltersList() {

  const {
    state,
    setHeaderList,
  } = useContext(DataContext);

  const list = state.actualHeadersList;
  var added = list.filter(h => h.filter === true);
  var removed = list.filter(h => h.filter === false);

  const handleToggle = (item) => () => {
    let objIndex = state.actualHeadersList.findIndex((header => header.key === item.key));
    let newData = state.actualHeadersList;

    if (item.filter === true) {
      newData[objIndex].filter = false;
      setHeaderList(newData);
    } else {
      newData[objIndex].filter = true;
      setHeaderList(newData);
    }
  };

  const customList = (title, items) => (
    <Card>
      <List
        sx={{
          width: 400,
          height: 700,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((item) => {
          const labelId = `transfer-list-all-item-${item.displayName}-label`;

          return (
            <ListItem
              key={item.key}
              role="listitem"
              button
              onClick={handleToggle(item)}
            >
              <ListItemText id={labelId} primary={item.displayName} />
              <ListItemIcon>
                {
                  item.filter ?
                    <IconButton aria-label="delete" size="large">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    :
                    <IconButton aria-label="add" size="large">
                      <AddIcon fontSize="small" />
                    </IconButton>
                }
              </ListItemIcon>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList('Choices', added)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">

        </Grid>
      </Grid>
      <Grid item>{customList('Chosen', removed)}</Grid>
    </Grid>
  );
}
