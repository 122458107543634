import React, { useEffect, useState, useContext } from "react";
import { ApiClient, ApiRoutes, useAggregatedState } from "../utils";
import { InsightContext } from "./";

export const UserContext = React.createContext(null);

const { Provider } = UserContext;
// const dateFormat = Formats.DateFormat;

export const UserProvider = (props) => {
    const { trackError } = useContext(InsightContext);
    const [state, setState] = useAggregatedState({
        applicationUser: null,
    });

    // ___________ UserCatalogue application user (admin, operator) ____________

    useEffect(() => {
        loadApplicationUser();
    }, [])

    const [isLoadingApplicationUser, setIsLoadingApplicationUser] = useState();
    const [applicationUser, setApplicationUser] = useState();

    const loadApplicationUser = async () => {
        try {
            setIsLoadingApplicationUser(true);

            const r = await ApiClient.get(ApiRoutes.User.GetMe());

            setState({
                applicationUser: r.data
            });

            setIsLoadingApplicationUser(false);

        } catch (e) {
            trackError(e);
            setIsLoadingApplicationUser(false);
        }
    }

    const cleanup = () => {
        setState({
        })
    }

    const [isAdmin, setAdmin] = useState();
    const [isLogged, setLogged] = useState()


    return (
        <Provider value={{
            isLoadingApplicationUser,
            cleanup,
            state,
            isAdmin,
            isLogged,

            setAdmin,
            setLogged,
            applicationUser,
        }}>
            {props.children}
        </Provider>
    );
}