import { Box } from "@mui/material";
import { default as React, useContext, useEffect } from "react";
import { DataContext } from '../contexts/DataContext';
import { HomePageProvider } from '../pages/HomePageContext';
import TransactionsDataGrid from "../ui-components/TransactionsDataGrid";
import TransactionsFilters from "../ui-components/TransactionsFilters";
const HomePageContent = (props) => {
    const {
        state,
        loadRequests,
        loadFiltersOptions,
    } = useContext(DataContext);

    useEffect(() => {
        loadRequests(state.filter);
        loadFiltersOptions(state.filter);
    }, [])

    return (
        <>
            <Box sx={{ mx: 20, my: 3 }}>
                <TransactionsFilters />
            </Box>

            <Box sx={{ mx: 2, my: 3 }}>
                <TransactionsDataGrid
                    {...props}
                />
            </Box>
        </>
    );
}

export const HomePage = (props) => {
    return (
        <HomePageProvider {...props}>
            {
                <HomePageContent {...props} />
            }
        </HomePageProvider>
    );
}