import React, { useState, useEffect, useRef } from 'react';

export const useAggregatedState = (source) => {
    const [state, _setState] = useState(source);
    const setState = (newState) => {
        _setState(prevState => {
            const assigned = Object.assign({ ...prevState }, newState);
            return assigned;
        });
    };

    return [state, setState];
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}