
import React, { useContext, useCallback, useState } from 'react';
import {
    Grid,
    Button,
    DialogContent,
    Tooltip,
    DialogTitle,
    Dialog,
    Divider,
    DialogActions,
    IconButton,
    Card,
    TextField,
} from "@mui/material";
import { DataContext } from '../contexts/DataContext';
import FiltersList from './FiltersList';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import debounce from 'lodash.debounce';
import AddIcon from '@mui/icons-material/Add';
import PropertyFilter from './PropertyFilter';
import SearchIcon from '@mui/icons-material/Search';

const columnIndex = {
    "id": 0,
    "businessProcess": 1,
    "businessSubProcess": 2,
    "transactionName": 3,
    "transactionType": 4,
    "shortDescription": 5,
    "sapCodeDescription": 6,
    "transactionDescriptionFullDetail": 7,
    "backingTransactions": 8,
    "transactionFunction": 9,
    "sodActivities": 10,
    "moduleSap": 11,
    "similarTransactionDifferenceDetails": 12,
    "similarTransactionExtensiveRestrictive": 13,
    "transactionRequestId": 14,
}
export default function TransactionsFilters(props) {
    const {
        state,
        setFilter,
        setPage,
        loadRequests,
        isSearching,
        setHeaderList,
    } = useContext(DataContext);

    const [isSearchingMode, setIsSearchingMode] = useState(false)

    const getRequests = (passedFilter) => {
        const text = passedFilter.searchText;

        if (text && text !== "") {
            setIsSearchingMode(true)
        } else {
            setIsSearchingMode(false)
        }
        setPage(0);
        loadRequests(passedFilter);
    }
    const isLoading = () => {
        return isSearching;
    }

    const updateValue = (filterUpdates) => {
        setFilter(filterUpdates);
        debouncedSave(filterUpdates);
    };

    const debouncedSave = useCallback(
        debounce((filterUpdates) => getRequests({ ...state.filter, ...filterUpdates }), 300),
        []
    );

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleAddAll = () => {
        let newData = state.actualHeadersList;

        newData.map(h => {
            if (h.filter !== 'blocked' && h.filter === false) {
                h.filter = true;
            }
        })
        setHeaderList(newData);
    };

    const handleRemoveAll = () => {
        let newData = state.actualHeadersList;

        newData.map(h => {
            if (h.filter !== 'blocked' && h.filter === true) {
                h.filter = false;
            }
        })
        setHeaderList(newData);
    };

    const handleClickSearch = () => {
        loadRequests(state.filter);
    };
    return <>
        <div>
            <Card>
                <CardActions >
                    <TextField
                        variant="standard"
                        onChange={(e) => updateValue({ searchText: e.target.value })}
                        disabled={isLoading()}
                        value={state.filter.searchText}
                        placeholder="Search Transactions"
                        inputProps={{ 'aria-label': 'search Transactions' }}
                        type="search"
                        size="small"
                        sx={{ margin: 'auto', alignItems: 'center', alignContent: 'center', width: '80%' }}
                        fullWidth
                    />
                </CardActions>
                
                <Collapse in={true} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Grid container GridClassKey="align-items-xs-center" columnSpacing={0} rowSpacing={0}>

                            <PropertyFilter xs={6} index={columnIndex.businessProcess}      optionsFilter={true} entityName={'process'} />
                            <PropertyFilter xs={6} index={columnIndex.shortDescription}     optionsFilter={false} />
                            <PropertyFilter xs={6} index={columnIndex.businessSubProcess}   optionsFilter={true} entityName={'subProcess'} />
                            <PropertyFilter xs={6} index={columnIndex.transactionType}      optionsFilter={true} entityName={'transactionType'} />
                            <PropertyFilter xs={6} index={columnIndex.transactionDescriptionFullDetail} optionsFilter={false} />
                            <PropertyFilter xs={6} index={columnIndex.sapCodeDescription}   optionsFilter={false} />
                            <PropertyFilter xs={6} index={columnIndex.transactionFunction}  optionsFilter={false} />
                            {/* <PropertyFilter xs={6} index={columnIndex.similarTransactionDifferenceDetails} optionsFilter={false} /> */}
                            {/* <PropertyFilter xs={6} index={columnIndex.similarTransactionExtensiveRestrictive} optionsFilter={false} /> */}
                            <PropertyFilter xs={6} index={columnIndex.backingTransactions}  optionsFilter={true} entityName={'backingTransactions'}/>
                            <PropertyFilter xs={6} index={columnIndex.sodActivities}        optionsFilter={true} entityName={'sodActivity'} />
                            <PropertyFilter xs={6} index={columnIndex.moduleSap}            optionsFilter={true} entityName={'module'} />

                            <Grid item xs={12} textAlign='center'>
                                <Tooltip title="Add Filters" aria-label="add-filters">
                                    <IconButton onClick={handleClickOpen} variant='contained'>
                                        <AddIcon />
                                    </IconButton >
                                </Tooltip>
                                <Tooltip title="Search" aria-label="search">
                                    <IconButton onClick={handleClickSearch} variant='contained'>
                                        <SearchIcon />
                                    </IconButton >
                                </Tooltip>
                            </Grid>

                            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'xl'} m>

                                <DialogTitle id="customized-dialog-title" onClose={handleClose}> Add or remove Filters to display </DialogTitle>

                                <DialogContent dividers>
                                    <FiltersList />
                                </DialogContent>
                                <DialogActions sx={{ display: 'inline'}} >
                                    <Button autoFocus variant="outlined" onClick={handleRemoveAll}  sx={{ color: 'black' }}> Remove all </Button>
                                    <Button autoFocus variant="outlined" onClick={handleAddAll} sx={{ color: 'black' }}> Add all </Button>
                                    <Button disabled sx={{ width: '140px' }}/>
                                    <Button autoFocus variant="outlined" onClick={handleClose} sx={{ color: 'black' }}> Confirm </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    </>;
}