import React from 'react';
export const HomePageContext = React.createContext(null);

const { Provider } = HomePageContext;
export const HomePageProvider = (props) => {

    return (
        <Provider >
            {props.children}
        </Provider>
    );
}