import React, { useContext, useState } from 'react';
import { InsightContext } from "./InsightContext";
import { useAggregatedState } from "../utils";
import { ApiRoutes, ApiClient } from "../utils";

export const ApplicationContext = React.createContext(null);

const { Provider } = ApplicationContext;
export const ApplicationProvider = (props) => {
    const { trackError } = useContext(InsightContext);

    const [state, setState] = useAggregatedState({
        applications: [],
        application: null,
        groups: [],
        sodActivities: [],
        availableApplications: [],
        role: null,
        roleMembers: [],
    });

    const [isLoadingApplications, setIsLoadingApplications] = useState(false);
    const loadApplications = async (pageSize, pageIndex, filter) => {
        try {
            setIsLoadingApplications(true);
            const r = await ApiClient.get(ApiRoutes.Admin.GetApplications({ size: pageSize, page: pageIndex, filter }));

            setState({
                applications: r.data
            });

            setIsLoadingApplications(false);

        } catch (e) {
            trackError(e);
            setIsLoadingApplications(false);
        }
    }

    const cleanApplications = () => {
        setState({
            applications: []
        })
    }

    const [isLoadingApplication, setIsLoadingApplication] = useState(false);

    const [isLoadingProcedures, setIsLoadingProcedures] = useState(false);

    const loadApplication = async (id) => {
        try {
            setIsLoadingApplication(true);

            const r = await ApiClient.get(ApiRoutes.Admin.GetApplication({ id }));

            setState({
                application: r.data
            });

            setIsLoadingApplication(false);

        } catch (e) {
            trackError(e);
            setIsLoadingApplication(false);
        }
    }
    const cleanApplication = () => {
        setState({
            application: null
        })
    }

    const loadApplicationFiles = async (appId) => {
        try {
            setIsLoadingProcedures(true);

            const r = await ApiClient.get(ApiRoutes.File.GetAppFiles({ appId }));

            setState({
                files: r.data
            });

            setIsLoadingProcedures(false);

        } catch (e) {
            trackError(e);
            setIsLoadingProcedures(false);
        }
    }
    const cleanAppFiles = () => {
        setState({
            files: []
        })
    }

    return (
        <Provider value={{
            state,
            isLoadingApplications,
            loadApplications,
            cleanApplications,
            isLoadingApplication,
            loadApplication,
            cleanApplication,
            loadApplicationFiles,
            cleanAppFiles,
            isLoadingProcedures
        }}>
            {props.children}
        </Provider>
    );
}