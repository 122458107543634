import { useState } from 'react';

export const useAggregatedState = (source) => {
    const [state, _setState] = useState(source);
    const setState = (newState) => {
        _setState(prevState => {
            const assigned = Object.assign({ ...prevState }, newState);
            return assigned;
        });
    };

    return [state, setState];
}