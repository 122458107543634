import React, { useContext } from "react";
import { useParams } from "react-router";
import { DetailPageContext, DetailPageProvider } from './DetailPageContext';
import { styled } from '@mui/material/styles';
import { Box, Link, } from '@mui/material';
import TransactionDetailToolbar from '../ui-components/TransactionDetailToolbar';
import { jsPDF } from "jspdf";
import { Link as RouterLink } from "react-router-dom";
import {
    Chip,
    Typography,
    Grid, Divider,
    Paper,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { TransactionFunction, TransactionType, TransactionModel, TransactionReferenceType, NavRoutes } from "../utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    '&:MuiGrid-root': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DetailPageContent = (props) => {
    const {
        state,
        loadRequestDetail,
    } = useContext(DetailPageContext);

    const { id } = useParams();

    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        let active = true;
        (
            async () => {
                setLoading(true);

                const f = state.filter;
                f.pageSize = 1;
                f.propertyFilters = [{
                    value: id,
                    name: "TransactionRequestId" // important: filter should be done on "TransactionRequestId", not "Id"
                }];

                await loadRequestDetail(f);

                if (!active) {
                    return;
                }
                setLoading(false);
            }
        )();
        return () => {
            active = false;
        };
    }, [id]);

    const req = state.request;

    function getBackingTransactionsText(backingTransactionType) {

        const backingTransactions = req[0].backingTransactions;
        if (backingTransactions && backingTransactions.length > 0) {
            return backingTransactions.filter(x => x.type === TransactionReferenceType.indexOf(backingTransactionType)).map(bt =>
                <Chip sx={{ mx: .4, my: .2 }} size={'small'} label={
                    <Link
                        component={RouterLink}
                        to={NavRoutes.DetailPage(bt.backingTransactionId)}
                        color="inherit"
                    >
                        {bt.backingTransactionName}
                    </Link>} />
            );
        }
        return null;
    }

    function getBackingTransactionsTextPdf(backingTransactionType) {
        var ret = '';
        const backingTransactions = req[0].backingTransactions;
        if (backingTransactions && backingTransactions.length > 0) {
            backingTransactions.filter(x => x.type === TransactionReferenceType.indexOf(backingTransactionType)).map(bt => {
                if (ret === '')
                    ret = bt.backingTransactionName;
                else
                    ret = ret + '; ' + bt.backingTransactionName;
            }
            );
        }
        return ret;
    }

    const getPdfExportData = () => {

        const result = [
            {
                id: 0,
                key: "Transaction",
                value: req[0].transactionName ? req[0].transactionName : ' '
            },
            {
                id: 1,
                key: "Transaction Description  Full Detail",
                value: req[0].transactionDescriptionFullDetail ? req[0].transactionDescriptionFullDetail : ' '
            },
            {
                id: 2,
                key: "Description EN SAP Code Description",
                value: req[0].sapCodeDescription ? req[0].sapCodeDescription : ' '
            },
            {
                id: 3,
                key: "Short Description",
                value: req[0].shortDescription ? req[0].shortDescription : ' '
            },
            {
                id: 4,
                key: "Transaction Function",
                value: req[0].transactionFunction != null ? TransactionFunction[req[0].transactionFunction].toString() : ' '
            },
            {
                id: 5,
                key: "Tree structure Reporting",
                value: getBackingTransactionsTextPdf('TreeStructure')
            },
            {
                id: 6,
                key: "Transaction Type",
                value: req[0].transactionType != null ? TransactionType[req[0].transactionType].toString() : ' '
            },
            {
                id: 7,
                key: "Module",
                value: req[0].moduleSap ? req[0].moduleSap.toString() : ' '
            },
            {
                id: 8,
                key: "SoD Activity",
                value: req[0].sodActivities ? req[0].sodActivities.toString() : ' '
            },
            {
                id: 9,
                key: "Business Process",
                value: req[0].businessProcess ? req[0].businessProcess.toString() : ' '
            },
            {
                id: 10,
                key: "Sub Process Business",
                value: req[0].businessSubProcess ? req[0].businessSubProcess.toString() : ' '
            },
            {
                id: 11,
                key: "Similar Transaction Reference",
                value: getBackingTransactionsTextPdf('Similar')
            },
            {
                id: 12,
                key: "Similar Transaction Difference Details",
                value: req[0].similarTransactionDifferenceDetails ? req[0].similarTransactionDifferenceDetails : ' '
            },
            {
                id: 13,
                key: "Similar Transaction Type",
                value: req[0].transactionModel != null ? TransactionModel[req[0].transactionModel].toString() : ' '
            }
        ];
        const headers = [
            {
                'id': 0,
                'name': 'key',
                'prompt': 0,
                'width': 65,
                'align': 'center',
                'padding': 0
            }, {
                'id': 1,
                'name': 'value',
                'prompt': 1,
                'width': 200,
                'align': 'left',
                'padding': 0
            }
        ];

        var doc = new jsPDF({
            putOnlyUsedFonts: true
            //, orientation: 'landscape'
        });
        doc.table(5, 5, result, headers, {
            autoSize: false,
            printHeaders: false
        });

        const timestamp = new Date().toJSON().replaceAll(':', '-').replaceAll('.', '-');
        var fileName = req[0].transactionName + '-' + timestamp + ".pdf";
        doc.save(fileName);
    }

    return (
        req && req[0] ?
            <>
                <Typography variant="h5" gutterBottom component="div" align="center" paddingTop={5}>
                    Transaction Details
                </Typography>
                <Typography variant="h6" align="center" paddingTop={1}>
                    {req[0].transactionName}
                </Typography>

                <Box sx={{ mx: 20, my: 2 }}>
                    {
                        props.isAdmin ? <TransactionDetailToolbar id={id} getPdfExportData={() => getPdfExportData()} /> : undefined
                    }
                </Box>
                <Box sx={{ mx: 20, my: 3, marginBottom: 20 }} id="exportPdf">
                    <Grid container spacing={2} sx={{ marginBottom: '40px' }}>
                        <Grid item xs={6}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableBody style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}>
                                        <StyledTableRow >
                                            <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Transaction</StyledTableCell>
                                            <StyledTableCell align="center">{req[0].transactionName ? req[0].transactionName : ''}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow >
                                            <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Transaction Description  Full Detail</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {req[0].transactionDescriptionFullDetail ? req[0].transactionDescriptionFullDetail : ''}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow >
                                            <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Description EN SAP Code Description</StyledTableCell>
                                            <StyledTableCell align="center">{req[0].sapCodeDescription ? req[0].sapCodeDescription : ''}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow >
                                            <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Short Description</StyledTableCell>
                                            <StyledTableCell align="center">{req[0].shortDescription ? req[0].shortDescription : ''}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow >
                                            <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Transaction Function</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    req[0].transactionFunction != null ?
                                                        <Chip size={'small'} label={
                                                            TransactionFunction[req[0].transactionFunction].toString()
                                                        } />
                                                        : ''
                                                }</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow >
                                            <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Tree structure Reporting</StyledTableCell>
                                            <StyledTableCell align="center">{getBackingTransactionsText('TreeStructure')}</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow >
                                            <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }} >Transaction Type</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    req[0].transactionType != null ?
                                                        <Chip size={'small'} label={
                                                            TransactionType[req[0].transactionType].toString()
                                                        } />
                                                        : ''
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Module</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    req[0].moduleSap ?
                                                        <Chip size={'small'} label=
                                                            {req[0].moduleSap.toString()} />
                                                        : ''
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={6} spacing={1}
                            sx={{
                                position: 'relative',
                            }}><Grid container rowSpacing={2}
                            >

                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="customized table">
                                            <TableBody style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}>
                                                <StyledTableRow >
                                                    <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>SoD Activity</StyledTableCell>
                                                    <StyledTableCell align="center">{req[0].sodActivities ? req[0].sodActivities.toString() : ''}</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow >
                                                    <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Business Process</StyledTableCell>
                                                    <StyledTableCell align="center">

                                                        {
                                                            req[0].businessProcess ?
                                                                <Chip size={'small'} label={req[0].businessProcess.toString()} />
                                                                : ''}</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow >
                                                    <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Sub Process Business</StyledTableCell>
                                                    <StyledTableCell align="center">{
                                                        req[0].businessSubProcess ?
                                                            <Chip size={'small'} label={req[0].businessSubProcess.toString()} />
                                                            : ''
                                                    }
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Divider>
                                </Divider>
                                <Grid item xs={12} sx={{
                                    position: 'absolute',
                                    bottom: '0',
                                    right: '0',
                                    left: '0',
                                    paddingLeft: '15px',
                                }}>
                                    <TableContainer fullWidth component={Paper}>
                                        <Table aria-label="customized table">
                                            <TableBody style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}>
                                                <StyledTableRow >
                                                    <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Similar Transaction Reference</StyledTableCell>
                                                    <StyledTableCell align="center">{getBackingTransactionsText('Similar')}</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow >
                                                    <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Similar Transaction Difference Details</StyledTableCell>
                                                    <StyledTableCell align="center">{req[0].similarTransactionDifferenceDetails ? req[0].similarTransactionDifferenceDetails : ' '}</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow >
                                                    <StyledTableCell width="40%" sx={{ fontWeight: 'bold' }}>Similar Transaction Type</StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {req[0].transactionModel != null ?
                                                            <Chip
                                                                label={TransactionModel[req[0].transactionModel].toString()}
                                                            />
                                                            : ''}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                            </Grid> </Grid>

                    </Grid>
                </Box>
            </>
            : <Typography variant="h5" gutterBottom component="div" align="center" paddingTop={5}>
                Transaction is not in Catalogue
            </Typography>
    );
}

export const DetailPage = (props) => {
    return (
        <DetailPageProvider {...props}>
            <DetailPageContent {...props} />
        </DetailPageProvider>
    );
}