export const headersList = [
    { key: 0, hidden: false, value: "Id", },
    { key: 1, hidden: false, value: "TransactionRequestId", },
    { key: 2, hidden: true, value: "BusinessProcess", },
    { key: 3, hidden: true, value: "BusinessSubProcess", },
    { key: 4, hidden: true, value: "TransactionName", },
    { key: 5, hidden: true, value: "TransactionType", },
    { key: 6, hidden: true, value: "ShortDescription", },
    { key: 7, hidden: true, value: "SapCodeDescription", },
    { key: 8, hidden: true, value: "TransactionDescriptionFullDetail", },
    { key: 9, hidden: true, value: "TransactionFunction", },
    { key: 10, hidden: true, value: "SodActivities", },
    { key: 11, hidden: true, value: "ModuleSap", },
    { key: 12, hidden: true, value: "BackingTransactions", },
    { key: 13, hidden: false, value: "SimilarTransactionDifferenceDetails", },
    { key: 14, hidden: false, value: "SimilarTransactionExtensiveRestrictive", },
    { key: 15, hidden: false, value: "TransactionModel", },
];


export const ApplicationRoles = {
    Administrator: "Administrator",
    Opener: "Opener",
    Business: "Business",
    Ciso: "Ciso",
    Display: "Display",
}

export const AuthPlatformTableNames = {
    Area: "Areas",
    Process: "Processes",
    SubProcess: "Sub Processes",
    Application: "Applications",
    SensitiveActivity: "Sensitive Activities",
    Table: "Tables",
    ReleaseToUsers: "Release to Users"
}

export const ComplexEntityNames = {
    Company: "Company",
    CompensatoryControl: "CompensatoryControl",
    Risk: "Risk",
    SodActivity: "SodActivity"
}

export const AllAuthPlatformTableNames = Object.keys(AuthPlatformTableNames).map(val => AuthPlatformTableNames[val]);

export const SapTableNames = {
    CG4: "CG4",
    Companies: "Companies",
    Countries: "Countries",
    CreditControlAreas: "Credit Control Areas",
    Currencies: "Currencies",
    CustomerGroups: "Customer Groups",
    DistributionChannels: "Distribution Channels",
    CustomerTypes: "Customer Types",
    DeliveryPriorities: "Delivery Priorities",
    Incoterms: "Incoterms",
    PricingGroups: "Pricing Groups",
    PricingLists: "Pricing Lists",
    RequestedDeliveryDateMandatory: "Requested Delivery Date Mandatory",
    ResponsibilityAreas: "Responsibility Areas",
    SalesDistricts: "Sales Districts",
    SalesGroups: "Sales Groups",
    SalesOffices: "Sales Offices",
    SalesOrganizations: "Sales Organizations",
    ShippingConditions: "Shipping Conditions",
    States: "States",
    TransportationZones: "Transportation Zones",
    PaymentTerms: "Payment Terms",
    Divisions: "Divisions",
    AccountAssignmentGroup: "Account Assignment Groups",
    AccountingClerks: "Accounting Clerks",
    CashManagementGroups: "Cash Management Groups",
    ReconcilationAccounts: "Reconcilation Accounts",
    SortKeys: "Sort Keys",
    Chains: "Chains",
    DeliveringPlants: "Delivering Plants",
    DunningProcedures: "Dunning Procedures"
}

export const LocalTableNames = {
    BusinessCategories: "Business Categories",
    PrincipalOperationCountries: "Principal Operation Countries",
    Ratings: "Ratings"
}

export const AppSettingNames = {
    ItalyCountryId: "ItalyCountryId",
    PerfumesBusinessCategoryId: "PerfumesBusinessCategoryId",
    AccGroupNoBankInfoIds: "AccGroupNoBankInfoIds",
    AccountStatement: "Account statement",
    Authorization: "Authorization",
    ExchangeRateType: "Exchange rate type",
    FlagPaymentHistory: "Flag payment history",
    PreviusAccountNumber: "Previus Account Number",
    PricingProcedure: "Pricing procedure",
    StatisticsGroup: "Statistics group",
    DivisionParfumes: "Division parfumes",
    DivisionJWA: "Division JWA",
    AccGrSH: "Acc_gr_SH",
}

export const RequestStatus = {
    Draft: 0,
    WaitingForCiso: 1,
    WaitingForApproval: 2,
    Approved: 3,
    Deleted: 4,
    ToBeReviewed: 5,
    WaitingForBusiness: 6
}

export const TodoAction = {
    OpenRequest: 0,
    OpenRolesList: 1
}

export const TransactionTypeOptions = {
    Standard: "Standard",
    Custom: "Custom",
}