import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
// import useStyles from "../../styles/useStyles";

const WelcomeName = () => {
    const { accounts } = useMsal();
    const [name, setName] = useState(null);
    // const style = useStyles();
    const style = {}

    useEffect(() => {
        if (accounts.length > 0) {
            setName(accounts[0].name.split(" ")[0]);
        }
    }, [accounts]);

    if (name) {
        return <Typography className={style.fontTitle} variant="button">welcome, {name}</Typography>;
    } else {
        return null;
    }
};

export default WelcomeName;