import ArticleIcon from '@mui/icons-material/Article';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Chip,
    Grid,
    IconButton,
    Box,
    Typography
} from '@mui/material';
import TransactionsToolbar from './TransactionsToolbar'
import { styled } from '@mui/material/styles';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DataContext } from '../contexts/DataContext';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { TransactionFunction, TransactionReferenceType, TransactionSimilarType, TransactionType, NavRoutes } from "../utils";

const MyTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 20,
    },
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: `1px solid ${theme.palette.primary.light === 'light' ? '#f0f0f0' : '#303030'}`,
    color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-renderingZone': {
        maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
        lineHeight: 'unset !important',
        maxHeight: 'none !important',
        whiteSpace: 'normal',
    },
    '& .MuiDataGrid-row': {
       // maxHeight: 'none !important',
       // maxHeight: '81 px',
    },
    '& .data--internal-clone-element': {
        whiteSpace: 'normal',
    },
    '& .MuiDataGrid-cell--withRenderer > span': {
        whiteSpace: 'normal',
    },
    '& .MuiTablePagination-displayedRows': {
        marginTop: '15px'
    },
    '& .MuiTablePagination-selectLabel': {
        marginTop: '12px'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
        fontSize: '30 px',
    },
    '& .MuiDataGrid-columnHeader': {
        // backgroundColor: theme.palette.grey[400],
        backgroundColor: theme.palette.primary.main,
    },
    '& .MuiDataGrid-columnHeader:first-child': {
        //color: theme.palette.primary.main,
    },
    '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: theme.palette.grey[100]
    },
    '&.MuiDataGrid-root': {
        border: 'none'
    },
    '& .MuiDataGrid-columnSeparator': {
        display: 'none'
    },
    '& .contract-trigger': {
        overflow: 'none',
        boxsizing: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
        height: '40px',
        minHeight: 'none',
        borderTop: 'none',
        marginBottom: '40px'
    },
})
);
function copyToClipboard(testo) {
    navigator.clipboard.writeText(testo);
}

function getrenderCell(params) {
    let testo;
    let fragment = '';
    let txtToCopy;
    switch (params.colDef.field) {
        case "transactionType":
            testo = TransactionType[params.row[params.field]];
            if (testo) {
                fragment = <Chip label={testo} size="small" />;
            }
            break;
        case "transactionFunction":
            testo = TransactionFunction[params.row[params.field]];
            if (testo) {
                fragment = <Chip label={testo} size="small" />;
            }
            break;
        case "similarTransactionExtensiveRestrictive":
            testo = TransactionSimilarType[params.row[params.field]];
            if (testo) {
                fragment = <Chip label={testo} size="small" />;
            }
            break;
        case "moduleSap":
            testo = params.row[params.field];
            if (testo) {
                fragment = <Chip label={testo} size="small" />;
            }
            break;
        case 'transactionDescriptionFullDetail':
            testo = params.row[params.field];
            if (testo) {
                fragment =
                    <div style={{
                        whiteSpace: 'normal',
                        display: "-webkit-box",
                        '-webkit-line-clamp': '3',
                        '-webkit-box-orient': 'vertical',
                        overflow: "hidden"
                    }}>
                        {testo}
                    </div>;
            }
            break;
        case "backingTransactions":
            if (params.row[params.field] && params.row[params.field].length > 0) {
                testo = [];
                txtToCopy = [];
                params.row[params.field].forEach(e => {
                    if (e.type === TransactionReferenceType.indexOf('TreeStructure')) {
                        if (testo.length !== 0) {
                            testo.push(<br />);
                        }
                        testo.push(e.backingTransactionName);
                        txtToCopy.push(e.backingTransactionName);
                    }
                });
                if (testo.length > 1) {
                    const treeStructure = params.row[params.field].filter(t => t.type === (TransactionReferenceType.indexOf('TreeStructure')));
                    const lbl = treeStructure[0].backingTransactionName;
                    const remaining = treeStructure.length - 1;
                    fragment =
                        <Grid container wrap="nowrap">
                            <Grid item xs zeroMinWidth >
                                <Typography noWrap>{lbl}</Typography>
                            </Grid>
                            <Grid item >
                                <Chip item label={'+' + remaining.toString()} size="small" />
                            </Grid>
                        </Grid>;
                }
            }
            break;
        case "sodActivities":
            if (params.row[params.field] && params.row[params.field].length > 0) {
                testo = [];
                txtToCopy = [];
                params.row[params.field].forEach(e => {
                    if (testo.length !== 0) {
                        testo.push(<br />);
                    }
                    testo.push(e);
                    txtToCopy.push(e);
                });
                if (params.row[params.field].length > 1) {
                    const lbl = params.row[params.field][0];
                    const remaining = params.row[params.field].length - 1;
                    fragment =
                        <Grid container wrap="nowrap">
                            <Grid item xs zeroMinWidth >
                                <Typography noWrap>{lbl}</Typography>
                            </Grid>
                            <Grid item >
                                <Chip item label={'+' + remaining.toString()} size="small" />
                            </Grid>
                        </Grid>;
                }
            }
            break;
        default:
            testo = params.row[params.field];
            if (testo) {
                fragment =  
                    <div style={{
                     whiteSpace: 'normal',
                        display: "-webkit-box",
                    '-webkit-line-clamp': '3',
                    '-webkit-box-orient': 'vertical',
                    overflow: "hidden"
                    }}>
                        {testo}
                    </div>;
            }
            break;
    }
    return (
        <MyTooltip title={
            <>
                {testo}
                <IconButton
                    onClick={() => copyToClipboard(txtToCopy ? txtToCopy : testo)}
                    variant='contained'
                    size='small'>
                    <ContentCopyIcon fontSize='small' />
                </IconButton >
            </>
        }
        // arrow style={{ whiteSpace: 'pre', }}
        >
            <span style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {
                    fragment ? fragment : testo
                }

            </span>
        </MyTooltip>
    );
}

export default function TransactionsDataGrid(props) {
    const {
        state,
        loadRequests,
        page,
        sortModel,
        pageSize,
        setPage,
        setPageSize,
        setFilter,
        setSortModel,
        setSelectionModel,
        prevSelectionModel,
        setSelectionModelState,
    } = useContext(DataContext);

    const getColumns = () => {
        let columns = [];
       
        columns.push({
            headerName: 'Hide Transaction',
            width: 120,
            field: "hideTransaction",
            renderCell: (params) => {
                return (
                    <MyTooltip title="Click to hide this transaction" aria-label="click-to-hide-this-transaction" >
                        <IconButton style={{ marginLeft: 15 }}
                            onClick={() => {
                                setSelectionModel(params.row.id);
                            }}
                            variant='contained'>
                            <VisibilityOffIcon />
                        </IconButton >
                    </MyTooltip>);
            }
        });

        columns.push({
            headerName: 'TCode Card',
            field: "click",
            width: 100,
            renderCell: (params) => {
                return (
                    <MyTooltip title="View Transaction Detail" aria-label="view-transaction-detail" >
                        <IconButton style={{ marginLeft: 15 }}
                            onClick={() => {
                                setSortModel(
                                    [
                                        {
                                            field: '',
                                            sort: ''
                                        }
                                    ]
                                );
                                setPageSize(100);
                                setPage(0);
                                setFilter({
                                    category: 2,
                                    searchText: "",
                                    pageIndex: 0,
                                    pageSize: 100,
                                    propertyFilters: [],
                                });
                                history.push(NavRoutes.DetailPage(params.row.transactionRequestId)) 
                                // 'transactionRequestId' is the id of the original entity, 'id' is the id of the normalized table
                                // we should use the former to enable backing transaction navigation by links
                            }
                            }
                            variant='contained'>
                            <ArticleIcon />
                        </IconButton >
                    </MyTooltip>);
            }
        });

        state.actualHeadersList.sort((a, b) => a.columnsOrder - b.columnsOrder).map(head => (
            columns.push({
                id: head.key,
                headerName: head.headerName,
                field: head.fieldName,
                description: head.headerName,
                headerAlign: head.headerAlign,
                flex: head.flex,
                minWidth: head.minWidth,
                hideable: true,
                hide: head.hidden,
                groupable: false,
                Tooltip,
                renderCell: (params) => (
                    getrenderCell(params)
                ),
            })
        ))
        return (columns);
    }

    const getRows = () => {
        let rows = [];
        if (state.requests !== undefined) {
            state.requests.forEach(t => {
                const o = {};
                Object.defineProperty(o, 'internalId', { value: t['id'], writable: false });

                state.actualHeadersList.forEach((head) => {
                    Object.defineProperty(o, head.fieldName, {
                        value: t[head.fieldName],
                        writable: false
                    });
                })
                rows.push(o);
            });
        }
        return (rows);
    }

    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    };

    const [addedtooltip,setAddedtooltip] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        let active = true;
        (
            async () => {
                setLoading(true);
                await loadRequests(state.filter);
                if (!active) { return; }
                setLoading(false);
            }
        )();
        return () => {
            active = false;
        };
    }, [page, pageSize, sortModel]);

    const history = useHistory();

    return (
        <>
            <TransactionsToolbar {...props} />
            <Box
                sx={{
                    height: 600,
                    '& .super-app-them  e--header': {
                        backgroundColor: '#787878',
                    },
                }}
            >
                <StyledDataGrid style={{ height: 600, width: '100%' }}
                    columns={getColumns()}
                    rows={getRows()}
                    rowsPerPageOptions={[100, 200, 300]}
                    disableExtendRowFullWidth={false}
                    getRowId={(row) => row.internalId}
                    density={'compact'}
                    headerHeight={50}
                    rowHeight={90}
                    disableColumnMenu={true}
                    filterMode={'server'}
                    autoPageSize={false}
                    hideFooterPagination={false}
                    hideFooter={false}
                    rowCount={state.totalItems}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                       // prevSelectionModel.current = selectionModel;
                        setPage(newPage);
                        setFilter({ pageIndex: newPage, pageSize: pageSize })
                    }}
                    onPageSizeChange={(newPageSize) => {
                        //prevSelectionModel.current = state.selectionModel;
                        setPageSize(newPageSize);
                        setPage(0);
                        setFilter({ pageIndex: 0, pageSize: newPageSize })
                    }}
                    //checkboxSelection
                    selectionModel={state.selectionModel}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                />
            </Box>
        </>
    );
}
