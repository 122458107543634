import React, { useContext } from "react";
import {
    Grid,
    Button,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    IconButton,
    Tooltip,
} from "@mui/material";
import ColumnsList from './ColumnsList';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataContext } from '../contexts/DataContext';
import { grey } from '@mui/material/colors';

export default function TransactionsToolbar(props) {

    const {
        state,
        setHeaderList,
        loadExport,
        isExporting,
        setHiddenRows,
    } = useContext(DataContext);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const getExportHandler = () => {
        loadExport();
    };
    const handleRemoveAll = () => {
        let newData = state.actualHeadersList;
        newData.map(h => {
            if (h.hidden !== 'blocked' && h.hidden === false) {
                h.hidden = true;
                h.columnsOrder = -1;
            }
        })
        setHeaderList(newData);
    };
    const handleAddAll = () => {
        let newData = state.actualHeadersList;
        newData.map(h => {
            if (h.hidden !== 'blocked' && h.hidden === true) {
                h.hidden = false;
                h.columnsOrder = Math.max(...newData.map(h => h.columnsOrder)) + 1;
            }
        })
        setHeaderList(newData);
    };
    return (
        <div>
            <Grid container alignContent="flex-start" >
                <Grid item xl={0.2} md={0.2} xs={0.2} />
                {
                    //props.isAdmin ?
                    <Grid item xl={0.5} md={0.5} xs={0.5} >
                        <Tooltip title="Download export" aria-label="download-export">
                            <IconButton
                                disabled={isExporting}
                                onClick={() => getExportHandler()}
                                color="primary"
                                aria-label="export"
                                variant="outlined"
                                size="large">
                                <GetAppIcon sx={{ color: grey[500] }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    //: undefined
                }
                <Grid item xl={0.5} md={0.5} xs={0.5} >
                    <Tooltip title="Add Columns" aria-label="advanced-filters">
                        <IconButton
                            onClick={handleClickOpen}
                            color="primary"
                            aria-label="toggle-filter-list"
                            variant="outlined"
                            size="large">
                            <AddCircleIcon sx={{ color: grey[500] }} />
                        </IconButton>
                    </Tooltip>

                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'xl'}>

                        <DialogTitle id="customized-dialog-title" onClose={handleClose}> Add or remove Columns to display </DialogTitle>
                        <DialogContent dividers> <ColumnsList /> </DialogContent>

                        <DialogActions sx={{ display: 'inline' }} >
                            <Button autoFocus variant="outlined" onClick={handleRemoveAll} sx={{ color: 'black' }}> Remove all </Button>
                            <Button autoFocus variant="outlined" onClick={handleAddAll} sx={{ color: 'black' }}> Add all </Button>
                            <Button disabled sx={{ width: '140px' }} />
                            <Button autoFocus variant="outlined" onClick={handleClose} sx={{ color: 'black' }}> Confirm </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                {
                    state.hiddenRows ?
                            <Grid item xl = { 0.5 } md = { 0.5 } xs = { 0.5 }>
                            < Tooltip title="Reset hidden rows" aria-label="Reset-hidden-rows">
                <IconButton
                    onClick={() => setHiddenRows(false)}
                    color="primary"
                    aria-label="Reset-hidden-rows"
                    variant="outlined"
                    size="large">
                    <VisibilityIcon sx={{ color: grey[500] }} />
                </IconButton>
                        </Tooltip>
                    </Grid>
        : undefined
}
            </Grid>
        </div>
    );
}