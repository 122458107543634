import axios from "axios";
import { loginRequest } from "../authConfig";

// axios instance for making requests 
export const ApiClient = axios.create();

// export const configureInterceptors = (msalInstance, history) => {
export const configureInterceptors = (msalInstance, history) => {

    // request interceptor for adding token
    ApiClient.interceptors.request.use(
        async (config) => {
            config.headers["x-api-challenge"] =
                localStorage.getItem("challenge") || "no-key-found";

            const token = await getToken(msalInstance);
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    ApiClient.interceptors.response.use(
        (response) => response,
        (error) => {
            console.debug(error);

            if (403 === error.response.status) {
                console.log('history.push(NavRoutes.Forbidden)');
            } else {
                return Promise.reject(error);
            }
        }
    )
}

const getAccountInfo = (msalInstance) => {
    const accounts = msalInstance.getAllAccounts();
    return accounts[0]; // Is this the correct way ? . 
};

const getToken = async (msalInstance) => {
    const account = getAccountInfo(msalInstance);
    try {
        const token = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account
        });
        return token.accessToken;
    } catch (error) {
        if (error.name === "InteractionRequiredAuthError") {
            return msalInstance
                .acquireTokenPopup(loginRequest)
                .then((resp) => {
                    return resp.accessToken;
                })
                .catch((err) => {
                    // routeToHome();
                    console.log(err);
                });
        } else {
            // routeToHome();
            console.log(error);
        }
    }
};

export const ApiRoutes = {
    Admin: {
        //GetApplications: () => "/api/Admin/GetApplications", //get --  old
    },
    User: {
        GetMe: () => "/User/GetMe",
    },
    Catalogue: {
        GetRequestsInCatalogue: () => "/Catalogue/GetRequestsInCatalogue",
        ExportFlatRequests: () => "/Catalogue/ExportFlatRequests",
        GetEntityOptions: (params) => compileRoute("/Catalogue/GetEntityOptions", params),
        GetActualEntityOptions: () => "/Catalogue/GetActualEntityOptions",
    },
}

const compileRoute = (route, params) => {
    if (params) {
        const props = Object.getOwnPropertyNames(params);
        if (props && props.length > 0) {
            route = `${route}?`
            props.filter(x => params[x] !== null && params[x] !== undefined ).forEach(prop => route = `${route}${prop}=${params[prop]}&`);
            route = route.substr(0, route.length - 1);
        }
    }
    return route;
}
