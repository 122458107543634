
import React, { useContext, useCallback, useState } from 'react';
import {
    Grid,
    IconButton,
    TextField,
    Autocomplete,
} from "@mui/material";
import { DataContext } from '../contexts/DataContext';
import { styled } from '@mui/material/styles';
import debounce from 'lodash.debounce';



export default function PropertyFilter(props) {
    const {
        state,
        setFilter,
        setPage,
        searchFiltersOption,
        loadRequests,
    } = useContext(DataContext);
    const columnDetail = state.actualHeadersList[state.actualHeadersList.findIndex(h => h.key === props.index)];
    const name = columnDetail.fieldName.charAt(0).toUpperCase() + columnDetail.fieldName.slice(1);
    let actualValue = state.filter.propertyFilters[state.filter.propertyFilters.findIndex(e => e.name === name)]?.value;
    const [isSearchingMode, setIsSearchingMode] = useState(false)

    const debouncedSave = useCallback(
        debounce((filterUpdates) => getRequests({ ...state.filter, ...filterUpdates }), 300),
        []
    );

    const getRequests = (passedFilter) => {
        const text = passedFilter.searchText;

        if (text && text !== "") {
            setIsSearchingMode(true)
        } else {
            setIsSearchingMode(false)
        }
        setPage(0);
        loadRequests(passedFilter);
    }

    const debouncedOption = useCallback(
        debounce((option, text) => getOption(option, text), 300),
        []
    );

    const getOption = (option, text) => {
        searchFiltersOption(option, text);
    }

    const onInputChange = (text, options) => {
        debouncedOption(name, text);
    };

    const updateValue = (value) => {
        const i = state.filter.propertyFilters.findIndex(e => e.name === name);
        const newData = state.filter;
        if (i === -1) {
            newData.propertyFilters.push(
                {
                    value: value.textContent,
                    name: name,
                }
            );
        } else {
            if (value.textContent === '') {
                newData.propertyFilters.splice(i, 1);
            } else {
                newData.propertyFilters[i].value = value;
            }
        }
        setFilter(newData);
        debouncedSave(newData);
    };

    const updateOptionsValue = (value, options) => {

        let i = state.filter.propertyFilters.findIndex(o => o.description === value.textContent);

        const newData = JSON.parse(JSON.stringify(state.filter));
        if (value.textContent !== '' && i === -1) {
            let val = undefined;
            switch (name) {
                case "BusinessProcess":
                    val = state.filtersOptions.process.find(x => x.description === value.textContent).description;
                    break;
                case "BusinessSubProcess":
                    val = state.filtersOptions.subProcess.find(x => x.description === value.textContent).description;
                    break;
                case "SodActivities":
                    val = state.filtersOptions.sodActivity.find(x => x.value === options[options.length-1].value).value;
                    break;
                case "ModuleSap":
                    //val = state.filtersOptions.module.find(x => x.description === value.textContent).value;
                    val = state.filtersOptions.module.find(x => x.value === value.textContent).value;
                    break;
                case "TransactionType":
                    val = state.filtersOptions.transactionType.find(x => x.value === value.textContent).value;
                    break;
                case "BackingTransactions":
                    val = state.filtersOptions.backingTransactions.find(x => x.value === value.textContent).value;
                    break;
                default:
                    val = '';
                    break;
            }
            newData.propertyFilters.push(
                {
                    value: val,
                    name: name,
                }
            );
        } else {
            newData.propertyFilters.splice(0, newData.propertyFilters.length);
            newData.propertyFilters = state.filter.propertyFilters.filter(e => e.name !== name);

            newData.propertyFilters.push(
                options.forEach((opt) => {
                    newData.propertyFilters.push(
                        {
                            value: (name === 'BackingTransactions' || name === 'ModuleSap' || name === 'SodActivities') ? opt.value : opt.description,
                            name: name,
                        }
                    );
                })
            )
            newData.propertyFilters = newData.propertyFilters.filter(e => e !== undefined);
        }
        setFilter(newData);
        debouncedSave(newData);
    };

    const options = [];
    if (state.filtersOptions && props.entityName && state.filtersOptions[props.entityName] &&
        state.filtersOptions[props.entityName].length !== options.length) {
        state.filtersOptions[props.entityName].forEach((opt) => {
            if (state.filter.propertyFilters.find(x => x.value === opt.description) === undefined) {
                options.push({
                    description: opt.description,
                    key: opt.key,
                    type: opt.type,
                    value: opt.value
                });
            }
        });
    }


    const onOpenAutocomplete = (e, options) => {
        if (e.textContent === '') {
            if (options === undefined)
                options = [];
            if (state.filtersOptions && props.entityName && state.filtersOptions[props.entityName] &&
                state.filtersOptions[props.entityName].length !== options.length) {
                state.filtersOptions[props.entityName].forEach((opt) => {
                    if (state.filter.propertyFilters.find(x => x.value === opt.description) === undefined) {
                        options.push({
                            description: opt.description,
                            key: opt.key,
                            type: opt.type,
                            value: opt.value
                        });
                    }
                });
            }
        }
    };

    return (
        <>
            {
                columnDetail.filter ?
                    <Grid item xs={6}>
                        {
                            props.optionsFilter === true ?
                                <Grid sx={{ width: '80%', margin: 'auto' }} >
                                    <Autocomplete
                                        style={{ marginTop: 8, lineHeight: '1rem' }}
                                        fullWidth
                                        multiple
                                        id="size-small-outlined-multi"
                                        size="small"
                                        limitTags={2}
                                        options={options}
                                        getOptionLabel={(option) => {
                                            if (props.entityName === 'backingTransactions' || props.entityName === 'module' )
                                                return option.value;
                                            else if (props.entityName === 'sodActivity')
                                            {
                                                const lbl = option.value + ' - ' + option.description;
                                                return lbl;
                                            }
                                            else
                                                return option.description;
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={columnDetail.displayName} />
                                        )}
                                        onInputChange={(e, options) =>
                                            onInputChange(e.target.value, options)}
                                        onChange={(e, options) =>
                                            updateOptionsValue(e.target, options)}
                                        onOpen={(e, options) =>
                                            onOpenAutocomplete(e.target, options)
                                        }
                                    />
                                </Grid>
                                :
                                <Grid sx={{ width: '80%', margin: 'auto' }} >
                                    <TextField
                                        style={{ marginTop: 8 }}
                                        size='small'
                                        margin='dense'
                                        fullWidth
                                        label={columnDetail.displayName}
                                        value={actualValue !== undefined ? actualValue : ''}
                                        type="search"
                                        onChange={(e) => updateValue(e.target.value)}
                                        InputProps={{ endAdornment: null }}
                                    />
                                </Grid>
                        }
                    </Grid>
                    :
                    undefined
            }
        </>
    );
}