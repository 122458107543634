import React, { useContext, useState } from "react";
import {
    Grid,
    IconButton,
    Tooltip,
} from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { DataContext } from '../contexts/DataContext';
import { grey } from '@mui/material/colors';
import { Icon } from '@iconify/react';


export default function TransactionDetailToolbar(props) {
    const {
        loadExport,
        isExporting,
    } = useContext(DataContext);

    const getExportHandler = (id) => {
        loadExport(id);
    };

    const [isExportingPdf, setIsExportingPdf] = useState(false);

    const getExportPdfHandler = () => {
        setIsExportingPdf(true);
        props.getPdfExportData();
        setIsExportingPdf(false);
    };

    return (
        <div>
            <Grid container alignContent="flex-start" >
                <Grid item xl={0.2} md={0.2} xs={0.2} />
                <Grid item xl={0.5} md={0.5} xs={0.5} >
                    <Tooltip title="Download export" aria-label="download-export">
                        <IconButton
                            disabled={isExporting}
                            onClick={() => getExportHandler(Number(props.id))}
                            color="primary"
                            aria-label="export"
                            variant="outlined"
                            size="large">
                            <Icon icon="icon-park-solid:excel" color="#b8b8b8" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xl={0.5} md={0.5} xs={0.5} >
                    <Tooltip title="Download export Pdf" aria-label="download-export">
                        <IconButton
                            disabled={isExportingPdf}
                            onClick={() => getExportPdfHandler()}
                            color="primary"
                            aria-label="export"
                            variant="outlined"
                            size="large">
                            <Icon icon="carbon:document-pdf" color="#b8b8b8" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    );
}