import React, { useState } from 'react';
import { useAggregatedState } from '../helpers';
import { ApiClient, ApiRoutes } from "../utils";

export const DetailPageContext = React.createContext(null);

const { Provider } = DetailPageContext;
export const DetailPageProvider = (props) => {
    const [state, setState] = useAggregatedState({
        filter: {
            request:{},
            category: 2,
            searchText: "",
            pageIndex: 0,
            pageSize: 1,
            propertyFilters: [
            ],
        },
    });

    const setFilter = (newData) => {
        const oldData = state.filter;
        setState({ filter: { ...oldData, ...newData } });
    }

    const [isLoadingRequests, setIsLoadingRequests] = useState(false);

    const loadRequestDetail = async (filter) => {
        try {
            setIsLoadingRequests(true);

            const r = await ApiClient.post(ApiRoutes.Catalogue.GetRequestsInCatalogue(), filter);

            const request = r.data.items;
            setState({ request  });
            setIsLoadingRequests(false);
        } catch (e) {
            console.debug(e);
            setIsLoadingRequests(false);
        }
    }

    return (
        <Provider value={{
            state,
            isLoadingRequests,
            loadRequestDetail,

            setFilter,
        }}>
            {props.children}
        </Provider>
    );
}


