import React, { useState } from 'react';
import { useAggregatedState, TransactionTypeOptions } from '../helpers';
import { ApiClient, ApiRoutes, TransactionEntityName } from "../utils";

export const DataContext = React.createContext(null);
const { Provider } = DataContext;
export const DataProvider = (props) => {

    const [state, setState] = useAggregatedState({
        transactions: [],
        isSearchingMode: false,
        totalItems: 0,
        requests: [],
        sortModel:
            [{
                field: '',
                sort: ''
            }],
        filter: {
            category: 2,
            searchText: "",
            pageIndex: 0,
            pageSize: 100,
            propertyFilters: [
            ],
        },
        selectionModel:[],
        hiddenRows: false,
        filtersOptions: [],
        actualHeadersList: [
            { key: 0, columnsOrder: -1, filter: 'blocked', hidden: 'blocked', minWidth: 50, flex: 0.8,  fieldName: "id",                                        headerName: "Id" ,                  displayName: ""  },
            { key: 1, columnsOrder: 0, filter: true, hidden: false, minWidth: 50, flex: 1,              fieldName: "businessProcess",                           headerName: "Process",              displayName: "Business Process" },
            { key: 2, columnsOrder: 1, filter: true, hidden: false, minWidth: 50, flex: 1,              fieldName: "businessSubProcess",                        headerName: "Sub Process",          displayName: "Business Sub Process" },
            { key: 3, columnsOrder: 2, filter: false, hidden: false, minWidth: 50, flex: 1,             fieldName: "transactionName",                           headerName: "Transaction",          displayName: "Transaction" },
            { key: 4, columnsOrder: 3, filter: false, hidden: false, minWidth: 50, flex: 0.7,           fieldName: "transactionType",                           headerName: "Type",                 displayName: "Transaction Type" },
            { key: 5, columnsOrder: 4, filter: true, hidden: true, minWidth: 50, flex: 1,               fieldName: "shortDescription",                          headerName: "Short Description",    displayName: "Short Description" },
            { key: 6, columnsOrder: 5, filter: false, hidden: false, minWidth: 100, flex: 1.2,          fieldName: "sapCodeDescription",                        headerName: "SAP Code Description", displayName: "Description EN SAP Code Description" },
            { key: 7, columnsOrder: 6, filter: true, hidden: false, minWidth: 50, flex: 1,              fieldName: "transactionDescriptionFullDetail",          headerName: "Full Description",          displayName: "Transaction Description Full Detail" },
            { key: 8, columnsOrder: 7, filter: false, hidden: true, minWidth: 80, flex: 1,              fieldName: "backingTransactions",                       headerName: "Tree Structure",       displayName: "Tree Structure Reporting" },
            { key: 9, columnsOrder: 8, filter: false, hidden: false, minWidth: 100, flex: 1.2,          fieldName: "transactionFunction",                       headerName: "Transaction Function", displayName: "Transaction Function Business" },
            { key: 10, columnsOrder: 9, filter: false, hidden: false, minWidth: 50, flex: 1,            fieldName: "sodActivities",                             headerName: "SoD Activity",         displayName: "SoD Activity" },
            { key: 11, columnsOrder: 10, filter: false, hidden: true, minWidth: 50, flex: 1,            fieldName: "moduleSap",                                 headerName: "Module",               displayName: "Module" },
            { key: 12, columnsOrder: -1, filter: 'blocked', hidden: true, minWidth: 50, flex: 1,        fieldName: "similarTransactionDifferenceDetails",       headerName: "Difference detail",    displayName: "" },
            { key: 13, columnsOrder: -1, filter: 'blocked', hidden: true, minWidth: 50, flex: 1,        fieldName: "similarTransactionExtensiveRestrictive",    headerName: "Extensive restrictive", displayName: "" },
            { key: 14, columnsOrder: -1, filter: 'blocked', hidden: true, minWidth: 50, flex: 1,        fieldName: "transactionRequestId",                      headerName: "Transaction request Id", displayName: "" },
        ],
    });
    const prevSelectionModel = React.useRef(state.selectionModel);
    
    const [sortModel, setSortModel] = React.useState([
        { field: '', sort: '' },
    ]);

    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(100);

    const setHiddenRows = (newVal) => {
        setState({ hiddenRows: newVal});
        if (!newVal) {
            setSelectionModel([]);
            state.filter.propertyFilters = [];
            loadRequests(state.filter);
        }
    }

    const setSelectionModel = (selData) => {
        const arr = [];
        arr.push(selData);
        if (arr && arr.length > 0) {
            arr.forEach(x => {
                if (state.selectionModel.indexOf(x) === -1) {
                    state.selectionModel.push(x);
                }
            })

            if (state.selectionModel.length) {
                setHiddenRows(true);
                const newData = state.filter;

                state.selectionModel.forEach(el => {
                    if (newData.propertyFilters.filter(f => f.name === "HiddenIds" && f.value === el).length === 0) {
                        newData.propertyFilters.push(
                            {
                                value: el,
                                name: "HiddenIds",
                            }
                        );
                    }
                })

                setFilter(newData);
                loadRequests({ ...state.filter, ...newData })
            }
        }
    }

    const setFilter = (newData) => {
        const oldData = state.filter;
        setState({ filter: { ...oldData, ...newData } });
    }

    const setHeaderList = (newData) => {
        setState({ actualHeadersList: [...newData] });
    }

    const [isLoadingRequests, setIsLoadingRequests] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const loadRequests = async (filter) => {
        try {
            setIsLoadingRequests(true);

            const newHeader = state.actualHeadersList;
            const transNameIndex = newHeader.findIndex((header => header.fieldName === 'transactionName'));
            if (filter.searchText !== "" && newHeader[transNameIndex].hidden === true) {
                newHeader[transNameIndex].hidden = false;
            }


            filter.propertyFilters.forEach(f => {
                if (f.value !== "") {
                    const fieldName = f.name.charAt(0).toLowerCase() + f.name.slice(1);
                    const idx = state.actualHeadersList.findIndex((header => header.fieldName === fieldName));
                    if (idx !== -1 && state.actualHeadersList[idx].hidden === true) {
                        newHeader[idx].columnsOrder = Math.max(...state.actualHeadersList.map(h => h.columnsOrder)) + 1;
                        newHeader[idx].hidden = false;
                    }
                }
            });
            if (sortModel && sortModel[0] && sortModel[0].field !== '') {
                filter.orderingProperty = sortModel[0].field;
                filter.orderingBy = sortModel[0].sort;
            }

            const r = await ApiClient.post(ApiRoutes.Catalogue.GetRequestsInCatalogue(), filter);

            const totalItems = r.data.totalItems;
            const requests = r.data.items;
            setHeaderList(newHeader);
            setState({ requests, totalItems });
            setIsLoadingRequests(false);
        } catch (e) {
            console.debug(e);
            setIsLoadingRequests(false);
        }
    }   
    

    const [isExporting, setIsExporting] = useState(false);
    const loadExport = async (id) => {
        try {
            setIsExporting(true);

            const newHeader = state.actualHeadersList;
            const transNameIndex = newHeader.findIndex((header => header.fieldName === 'transactionName'));
            if (state.filter.searchText !== "" && newHeader[transNameIndex].hidden === true) {
                newHeader[transNameIndex].hidden = false;
            }

            if (id) {
                state.filter.propertyFilters = [{
                    value: id,
                    name: "TransactionRequestId"
                }];
            }

            state.filter.propertyFilters.forEach(f => {
                if (f.value !== "") {
                    const fieldName = f.name.charAt(0).toLowerCase() + f.name.slice(1);
                    const idx = state.actualHeadersList.findIndex((header => header.fieldName === fieldName));
                    if (idx !== -1 && state.actualHeadersList[idx].hidden === true) {
                        newHeader[idx].columnsOrder = Math.max(...state.actualHeadersList.map(h => h.columnsOrder)) + 1;
                        newHeader[idx].hidden = false;
                    }
                }
            });
            if (sortModel && sortModel[0] && sortModel[0].field !== '') {
                state.filter.orderingProperty = sortModel[0].field;
                state.filter.orderingBy = sortModel[0].sort;
            }

            const r = await ApiClient.post(ApiRoutes.Catalogue.ExportFlatRequests(), state.filter, {
                responseType: 'blob'
            });

            let blb = new Blob([r.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            var url = window.URL.createObjectURL(blb);

            const link = document.createElement('a');
            link.href = url;
            // ISO 8601
            const timestamp = new Date().toJSON().replaceAll(':', '-').replaceAll('.', '-');
            link.setAttribute('download', `BulgariTransactionsList-${timestamp}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();


            setIsExporting(false);
        } catch (e) {
            console.debug(e);
            setIsExporting(false);
        }
    }   

    const [isLoadingFiltersOptions, setIsLoadingFiltersOptions] = useState(false);
    const loadFiltersOptions = async (filter) => {
        try {
            setIsLoadingFiltersOptions(true);

            let codeQuery = "";


            if (state.filtersOptions.length === 0) {

                const transactionType = [
                    { description: TransactionTypeOptions.Standard, type: 0, key: 0, value: TransactionTypeOptions.Standard },
                    { description: TransactionTypeOptions.Custom, type: 0, key: 1, value: TransactionTypeOptions.Custom }
                ];

                const process = await ApiClient.post(ApiRoutes.Catalogue.GetActualEntityOptions(), filter );
                const subProcess = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('SubProcess'), codeQuery }));
                const module = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Area'), codeQuery }));
                const sodActivity = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('SodActivity'), codeQuery }));
                const backingTransactions = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('TransactionRequest'), codeQuery }));

                setState({
                    filtersOptions: {
                        transactionType: transactionType,
                        process: process.data,
                        subProcess: subProcess.data,
                        module: module.data,
                        sodActivity: sodActivity.data,
                        backingTransactions: backingTransactions.data
                    }
                });
            }
            setIsLoadingFiltersOptions(false);
        } catch (e) {
            console.debug(e);
            setIsLoadingFiltersOptions(false);
        }
    }

    const [isSearchingFiltersOption, setIsSearchingFiltersOption] = useState(false);
    const searchFiltersOption = async (optionType, text) => {
        try {
            setIsSearchingFiltersOption(true);

            let codeQuery = '';
            if (text !== undefined)
                codeQuery = text;

                switch (optionType) {
                    case "BusinessProcess":
                const process = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Process'), codeQuery }));
                        setState({
                            filtersOptions: {
                                process: process.data,
                            }
                        });
                        break;
                    case "BusinessSubProcess":
                const subProcess = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('SubProcess'), codeQuery }));
                        setState({
                            filtersOptions: {
                                subProcess: subProcess.data,
                            }
                        });
                        break;
                    case "ModuleSap":
                const module = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('Area'), codeQuery }));
                        setState({
                            filtersOptions: {
                                module: module.data,
                            }
                        });
                        break;
                    case "SodActivities":
                const sodActivity = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('SodActivity'), codeQuery }));
                        setState({
                            filtersOptions: {
                                sodActivity: sodActivity.data,
                            }
                        });
                        break;
                    case "BackingTransactions":
                const backingTransactions = await ApiClient.get(ApiRoutes.Catalogue.GetEntityOptions({ entityName: TransactionEntityName.indexOf('TransactionRequest'), codeQuery }));
                        setState({
                            filtersOptions: {
                                backingTransactions: backingTransactions.data
                            }
                        });
                        break;
                    default:
                        break;
                }
            setIsSearchingFiltersOption(false);
        } catch (e) {
            console.debug(e);
            setIsSearchingFiltersOption(false);
        }
    }

    return (
        <Provider value={{
            state,
            isLoadingRequests,
            isSearching,
            loadRequests,
            loadFiltersOptions,
            searchFiltersOption,
            isExporting,
            loadExport,
            prevSelectionModel,
            setSelectionModel,
            sortModel,
            setSortModel,
            page,
            setPage,
            pageSize,
            setPageSize,
            setFilter,
            setHeaderList,
            setHiddenRows,
        }}>
            {props.children}
        </Provider>
    );
}