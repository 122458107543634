import React, { Component, useContext } from 'react';
import { InsightProvider, ApplicationProvider, UserProvider, UserContext } from "./contexts"
import { ApplicationRoles, configureInterceptors, NavRoutes } from "./utils";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { HomePage } from "./pages/HomePage";
import { DetailPage } from './pages/DetailPage';
import { Switch, Route } from "react-router-dom";
import './custom.css'
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { PageLayout } from "./ui-components/page-layout";
import { theme } from "./styles/theme";
import { loginRequest } from "./authConfig";
import { DataProvider } from './contexts/DataContext';

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    configureInterceptors(this.props.pca);
  }

  render() {
    return (
      <MsalProvider instance={this.props.pca}>
        <InsightProvider>
          <UserProvider>
            <ApplicationProvider>
              <DataProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <AuthProvider />
                  </ThemeProvider>
                </StyledEngineProvider>
              </DataProvider>
            </ApplicationProvider>
          </UserProvider>
        </InsightProvider>
      </MsalProvider>
    );
  }
}

function AuthProvider() {
  const { inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const {
    state,
    isLogged,
    setLogged,
    isAdmin,
    setAdmin
  } = useContext(UserContext);

  const authRequest = {
    ...loginRequest
  };

  const { applicationUser } = state;
  const isUserInRole = (role) => applicationUser.userCatalogueSpecificRoles.some(x => x === role);
  const allowRoute = () => {

    if (!isAuthenticated) {
      instance.loginRedirect(authRequest);
    }
    if (inProgress !== "none" || !applicationUser) {
      return true;
    }

    if (applicationUser?.userCatalogueSpecificRoles?.length > 0) {
      if (isUserInRole(ApplicationRoles.Admin)) {
        setAdmin(true);
        setLogged(true);
      } else {
        setLogged(true);
        setAdmin(false);
      }
      return true;
    }
    return false;
  }

  if (allowRoute()) {
    return (
      <PageLayout isLogged={isLogged} >
        <Pages setAdmin={setAdmin} isAdmin={isAdmin} isLogged={isLogged} />
      </PageLayout >
    );
  }

  return instance.loginRedirect(authRequest)
}

function Pages(props) {
  return (
    <Switch>
      <ProtectedRoute path={NavRoutes.DetailPageTemplate} >
        <DetailPage isAdmin={props.isAdmin} isLogged={props.isLogged} />
      </ProtectedRoute>

      <ProtectedRoute exact path={NavRoutes.HomePage}>
        <HomePage isAdmin={props.isAdmin} isLogged={props.isLogged} />
      </ProtectedRoute>
    </Switch>
  )
}

function ProtectedRoute(props) {
  return <Route {...props} />;
}