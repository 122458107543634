// import MainAppBar  from "../MainAppBar"
import Footer from "./Footer";
import NavBar from "./NavBar";

const Content = (props) => {
    return (
        <>
            <NavBar />
            {props.children}
            <Footer />
        </>
    );
}

export const PageLayout = (props) => {

    if (props.isLogged === true) {
        return (
            <Content {...props} />
        );
    } else {
        return <></>;
    }
};